import AuditDTO from '@/dtos/AuditDTO';
import SessionDTO from '@/dtos/SessionDTO';
import { app, authApp } from '@/firebase';
import { useAlertAudit } from '@/hooks/alertAudit';
import FirebaseService from '@/services/FirebaseService';
import vm from '@/viewModels/MainViewModel';
import { collection, doc, getDocsFromServer, getFirestore, query, updateDoc, where, onSnapshot, arrayRemove, arrayUnion, Firestore, DocumentData } from "firebase/firestore";

const COLLECTION_EXAMS = "exam__exams";

const alertAudit = useAlertAudit();

const getDb = async () => {
    const user = authApp.currentUser;

    //reautenticar usuário caso tenha perdido a sessão
    if (!user) {
        let email = "";
        let password = "";
        if (vm.sessionFireBase) {
            email = vm.sessionFireBase?.email as string;
            password = atob(vm.sessionFireBase?.password as string);
        }
        else {
            const session = JSON.parse(localStorage.getItem("sessionFireBase")!) as SessionDTO;
            email = session?.email;
            password = atob(session?.password);
        }
        await FirebaseService.login(email, password);
    }

    const db = getFirestore(app);
    return db;
}

export const getDocExam = async (schedulingId: string) => {
    const db = await getDb();
    const examsRef = collection(db!, COLLECTION_EXAMS);
    const q = query(examsRef, where("metadata.schedulingId", "==", schedulingId));
    const querySnapshot = await getDocsFromServer(q);
    const docExam = querySnapshot.docs.find(doc => doc.data().metadata.schedulingId == schedulingId)!;
    return docExam ? docExam : null;
};

export const updateDocExam = async (audit: AuditDTO) => {
    const db = await getDb();
    const docExam = await getDocExam(audit.schedulingId!);
    if (docExam) {
        const eRef = doc((db as Firestore), COLLECTION_EXAMS, docExam.id!);
        if (docExam.data().audit != undefined) {
            const auditor = docExam.data().audit.find((a: AuditDTO) => a.auditorCpf == audit.auditorCpf) as AuditDTO;
            if (auditor && auditor.statusAudit != audit.statusAudit) {
                await updateDoc(eRef, {
                    audit: arrayRemove({
                        schedulingId: auditor.schedulingId,
                        statusAudit: auditor.statusAudit,
                        auditorCpf: auditor.auditorCpf,
                        auditorName: auditor.auditorName
                    })
                })
            }
        }

        await updateDoc(eRef, {
            audit: arrayUnion({
                schedulingId: audit.schedulingId,
                statusAudit: audit.statusAudit,
                auditorCpf: audit.auditorCpf,
                auditorName: audit.auditorName
            })
        })
    }
}

export const removeAuditorDocExam = async (schedulingId: string, auditorCpf: string) => {
    const db = await getDb();
    const docExam = await getDocExam(schedulingId!);
    if (docExam) {
        const eRef = doc((db as Firestore), COLLECTION_EXAMS, docExam.id!);
        if (docExam.data().audit != undefined) {
            const auditor = docExam.data().audit.find((a: AuditDTO) => a.auditorCpf == auditorCpf) as AuditDTO;
            if (auditor) {
                await updateDoc(eRef, {
                    audit: arrayRemove({
                        schedulingId: auditor.schedulingId,
                        statusAudit: auditor.statusAudit,
                        auditorCpf: auditor.auditorCpf,
                        auditorName: auditor.auditorName
                    })
                })
            }
        }
    }
}

export const watchDocExam = async (schedulingId: string) => {
    const db = await getDb();
    const docExam = await getDocExam(schedulingId);
    let previousData: AuditDTO[] = [];
    
    if (docExam) {
        const docRef = doc((db as Firestore), COLLECTION_EXAMS, docExam?.id as string);

        return onSnapshot(docRef, { includeMetadataChanges: true }, (doc) => {
            const data = doc.data();
            if (!!data?.audit && data.audit.length > 0) {
                const auditor = data.audit.find((a: AuditDTO) => a.statusAudit == 'auditing') as AuditDTO;
                const viewers = data.audit.filter((a: AuditDTO) => a.statusAudit == 'viewing') as Array<AuditDTO>;
                if (auditor && auditor.auditorCpf != vm.user?.cpf) {
                    alertAudit({
                        title: "Exame em análise",
                        messageAuditor: "Este exame está sendo auditado por:",
                        auditor: auditor,
                        message: "E está sendo visualizado por:",
                        audit: viewers.filter((a: AuditDTO) => a.auditorCpf != vm.user?.cpf)
                    })
                } else if (viewers.length > 1 && viewers.some((a: AuditDTO) => a.auditorCpf != vm.user?.cpf)) {
                    alertAudit({
                        title: "Exame em visualização",
                        message: "Este exame está sendo visualizado por:",
                        audit: viewers.filter((a: AuditDTO) => a.auditorCpf != vm.user?.cpf)
                    });
                }
            }

            previousData = data?.audit;
        });
    }
}


const diffAudit = (previousData: AuditDTO[], currentData: AuditDTO[]) => {
    const uniqueInPreviousData = previousData.filter(obj1 => !currentData.some(obj2 => isEqual(obj1, obj2)));
    return [...uniqueInPreviousData];
};

const isEqual = (audit1: AuditDTO, audit2: AuditDTO): boolean => {
    return JSON.stringify(audit1) === JSON.stringify(audit2);
}